<script setup lang="ts">
import { DcEndPoints, OrganizationUserRole } from "docuchatcommontypes";
import type { TableColumn } from "~/types/nuxtUI";

definePageMeta({
  layout: "app",
  middleware: ["dc-auth"],
});

const { currentLink } = useNavigation();

const toast = useToast();

const { user, organization, organizationUsers } = storeToRefs(useUserStore());
const { fetchTeamMembers } = useUserStore();

// Columns and rows
const columns: TableColumn[] = [
  {
    key: "user",
    label: "User",
    sortable: true,
  },
  {
    key: "role",
    label: "Role",
    sortable: true,
  },
  {
    key: "status",
    label: "Status",
    sortable: true,
  },
  {
    key: "actions",
  },
];

const sort = ref<{ column: string; direction: "asc" | "desc"; }>({
  column: "user",
  direction: "asc",
});

// Table filters
const query = ref("");

const selectedRoles = ref<OrganizationUserRole[]>([]);
const roleOptions = computed(() => {
  if (user.value?.organizationUserRole === OrganizationUserRole.Admin)
    return [OrganizationUserRole.Member];
  else
    return [OrganizationUserRole.Admin, OrganizationUserRole.Member];
});

const selectedStatuses = ref<string[]>([]);
const statusOptions = ["Joined", "Pending"];

const rows = computed(() => {
  let users = organizationUsers.value?.filter(u => u.emailOrUserName !== user.value?.emailorUserName) ?? [];

  // For admins, only show members
  if (user.value?.organizationUserRole === OrganizationUserRole.Admin)
    users = users?.filter(u => u.organizationUserRole === OrganizationUserRole.Member);

  return users.map(u => ({
    user: u.emailOrUserName,
    role: u.organizationUserRole,
    status: u.hasJoinedOrganization ? "Joined" : "Pending",
  }));
});

const filteredRows = computed(() => {
  return rows.value.filter((row) => {
    const roleMatch = selectedRoles.value.length === 0 || selectedRoles.value.includes(row.role);
    const statusMatch = selectedStatuses.value.length === 0 || selectedStatuses.value.includes(row.status);
    const queryMatch = row.user.toLowerCase().includes(query.value.toLowerCase());
    return roleMatch && statusMatch && queryMatch;
  });
});

// Table pagination
const page = ref(1);
const pageCount = 16;
const pageFrom = computed(() => (page.value - 1) * pageCount + 1);
const pageTo = computed(() => Math.min(page.value * pageCount, filteredRows.value.length));
const paginatedRows = computed(() => {
  return filteredRows.value.slice((page.value - 1) * pageCount, (page.value) * pageCount);
});

watch([query, selectedRoles, selectedStatuses], () => {
  page.value = 1;
});

// Add
const showAddModal = ref(false);
const addPending = ref(false);

async function handleAdd(users: Array<{ email: string; role: OrganizationUserRole; }>) {
  addPending.value = true;

  try {
    const newUsers = users.map(u => ({ emailOrUserName: u.email, organizationUserRole: u.role }));
    const endpoint = DcEndPoints.UpsertOrganizationUser({
      organizationId: organization.value!.id,
      users: [...(organizationUsers.value ?? []), ...newUsers],
    });
    const response = await useApi(endpoint);

    if (response.success) {
      toast.add({
        title: "Users successfully added",
        description: "Email addresses will receive an invite. For usernames, you will shortly download a CSV file with their login credentials.",
      });

      if (response.content.credentials)
        downloadAsCsvFile("newDocuchatUsers", response.content.credentials);

      await fetchTeamMembers();
    }
    else {
      throw createError(response.message ?? "This was an unkown error. Please contact support.");
    }
  }
  catch (error: any) {
    toast.add({
      title: "We could not add the users",
      icon: "i-carbon-warning",
      description: error.message,
      color: "red",
    });
  }

  showAddModal.value = false;
  addPending.value = false;
}

// Edit
const showEditModal = ref(false);
const targetForEdit = ref<string>("");
const editPending = ref(false);

async function handleUpdateRole(newRole: OrganizationUserRole) {
  if (!targetForEdit.value)
    throw createError("You are trying to edit a user but it is not selected.");

  editPending.value = true;

  try {
    const updatedOrganizationusers = organizationUsers.value?.map((u) => {
      if (u.emailOrUserName === targetForEdit.value)
        return { ...u, organizationUserRole: newRole };
      return u;
    });

    if (!updatedOrganizationusers)
      throw createError("Could not find the user to update in the organization.");

    const endpoint = DcEndPoints.UpsertOrganizationUser({
      organizationId: organization.value!.id,
      users: updatedOrganizationusers,
    });
    const response = await useApi(endpoint);

    if (!response.success)
      throw createError(response.message ?? "Failed to update user role.");

    await fetchTeamMembers();
    showEditModal.value = false;
  }
  catch (error: any) {
    toast.add({
      title: "We could not update the user role",
      icon: "i-carbon-warning",
      description: error.message,
      color: "red",
    });
  }

  editPending.value = false;
}

// Delete
const showDeleteModal = ref(false);
const targetForDelete = ref<string>("");
const deletePending = ref(false);

async function handleDelete() {
  if (!targetForDelete.value)
    throw createError("You are trying to delete a user but it is not selected.");

  deletePending.value = true;

  try {
    const updatedOrganizationusers = organizationUsers.value?.filter(u => u.emailOrUserName !== targetForDelete.value);

    if (!updatedOrganizationusers)
      throw createError("Could not find the user to delete in the organization.");

    const endpoint = DcEndPoints.UpsertOrganizationUser({
      organizationId: organization.value!.id,
      users: updatedOrganizationusers,
    });
    const response = await useApi(endpoint);

    if (!response.success)
      throw createError(response.message ?? "Failed to delete user.");

    await fetchTeamMembers();
    showDeleteModal.value = false;
  }
  catch (error: any) {
    toast.add({
      title: "We could not delete the user",
      icon: "i-carbon-warning",
      description: error.message,
      color: "red",
    });
  }

  deletePending.value = false;
}

// Roles help
const showRolesHelp = ref(false);
</script>

<template>
  <UDashboardPage>
    <UDashboardPanel grow>
      <UDashboardNavbar :title="currentLink?.label">
        <template #right>
          <UButton
            variant="solid"
            label="Add User"
            trailing-icon="i-carbon-add-large"
            @click.prevent="showAddModal = true"
          />
        </template>
      </UDashboardNavbar>

      <UDashboardToolbar>
        <template #left>
          <UInput
            v-model="query"
            icon="i-carbon-search"
            size="xs"
            autocomplete="off"
            placeholder="Search users"
            class="hidden lg:block"
          />

          <USelectMenu
            v-model="selectedRoles"
            icon="i-carbon-identification"
            size="xs"
            placeholder="Role"
            multiple
            :options="roleOptions"
            :ui-menu="{ option: { base: 'capitalize' } }"
          />

          <USelectMenu
            v-model="selectedStatuses"
            icon="i-carbon-email-new"
            size="xs"
            placeholder="Status"
            multiple
            :options="statusOptions"
            :ui-menu="{ option: { base: 'capitalize' } }"
          />
        </template>

        <template #right>
          <div class="space-x-3">
            <UButton
              variant="link"
              size="xs"
              label="Learn More About Roles"
              @click.prevent="showRolesHelp = true"
            />
          </div>

          <UDashboardModal
            v-model="showRolesHelp"
            title="Roles"
            description="Learn more about roles and permissions in DocuChat."
            icon="i-carbon-identification"
          >
            <DcMarkdown
              size="sm"
              :md="getOrganizationRolesExplanation()"
            />
          </UDashboardModal>
        </template>
      </UDashboardToolbar>

      <UDashboardPanelContent>
        <UDashboardCard>
          <UTable
            :columns="columns"
            :rows="paginatedRows"
            :sort="sort"
            sort-asc-icon="i-carbon-sort-ascending"
            sort-desc-icon="i-carbon-sort-descending"
            :sort-button="{
              icon: 'i-carbon-arrows-vertical',
            }"
            :empty-state="{
              icon: 'i-carbon-user-multiple',
              label: 'You have not invited any users yet.',
            }"
          >
            <template #status-data="{ row }">
              <UBadge
                variant="subtle"
                :color="row.status === 'Joined' ? 'primary' : 'yellow'"
              >
                {{ row.status }}
              </UBadge>
            </template>

            <template #actions-data="{ row }">
              <div class="flex items-center gap-2">
                <UTooltip text="Edit Role">
                  <UButton
                    variant="solid"
                    size="xs"
                    color="gray"
                    icon="i-carbon-edit"
                    @click.prevent="() => { targetForEdit = row.user; showEditModal = true; }"
                  />
                </UTooltip>

                <UTooltip text="Delete">
                  <UButton
                    variant="soft"
                    class="ring-1 ring-inset ring-red-500 ring-opacity-25 dark:ring-red-400 dark:ring-opacity-25"
                    size="xs"
                    color="red"
                    icon="i-carbon-trash-can"
                    @click.prevent="() => { targetForDelete = row.user; showDeleteModal = true; }"
                  />
                </UTooltip>
              </div>
            </template>
          </UTable>

          <!-- Pagination -->
          <div
            v-if="filteredRows.length > pageCount"
            class="flex flex-wrap items-center justify-between gap-2 border-t border-gray-200 px-3 py-3.5 dark:border-gray-700"
          >
            <div>
              <span class="text-sm leading-5">
                Showing
                <span class="font-medium">{{ pageFrom }}</span>
                to
                <span class="font-medium">{{ pageTo }}</span>
                of
                <span class="font-medium">{{ filteredRows.length }}</span>
                results
              </span>
            </div>

            <UPagination
              v-model="page"
              :page-count="pageCount"
              :total="filteredRows.length"
            />
          </div>
        </UDashboardCard>
      </UDashboardPanelContent>

      <!-- Add Modal -->
      <LazyModalUserAdd
        v-model="showAddModal"
        :loading="addPending"
        :role-options="roleOptions"
        :organization-users="organizationUsers ?? []"
        @on-add="handleAdd"
      />

      <!-- Edit Modal -->
      <LazyModalUserEdit
        v-model="showEditModal"
        :loading="editPending"
        :current-role="rows.find(r => r.user === targetForEdit)?.role"
        :options="roleOptions"
        @on-edit="handleUpdateRole"
      />

      <!-- Delete Confirmation Modal -->
      <LazyModalConfirmationDelete
        v-model="showDeleteModal"
        :loading="deletePending"
        :resource-name="targetForDelete"
        @on-delete="handleDelete"
      />
    </UDashboardPanel>
  </UDashboardPage>
</template>

export function getOrganizationRolesExplanation() {
  return `When you invite your team to DocuChat, each user can be assigned one of the following roles:

| Permissions                                                     | Super Admin | Admin | Member |
|-----------------------------------------------------------------|-------------|-------|--------|
| Manage billing information and view usage details               | ✅          | ❌    | ❌      |
| Invite or delete **admins** of the organization                 | ✅          | ❌    | ❌      |
| Invite or delete **members** of the organization                | ✅          | ✅    | ❌      |
| Create, edit, delete, and view all chatbots and documents       | ✅          | ✅    | ❌      |
| Access analytics information about chatbots                     | ✅          | ✅    | ❌      |
| View and chat with chatbots shared with the organization        | ✅          | ✅    | ✅      |
| Access documents shared with the organization                   | ✅          | ✅    | ✅      |

By assigning appropriate roles to your team members, you can ensure that each user has access to the necessary features and information while maintaining the security and integrity of your account.`;
}
